<template>
  <v-container class="test-form px-14 pa-14">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(saveData)"
        method="post"
        id="check-login-form"
      >
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.user_full_name }}
            <span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput
                v-model="user_full_name"
                :text="user_full_name"
              ></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.title
            }}<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="title" :text="title.title"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.slug }}
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="slug" :text="slug"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.content
            }}<span class="red--text font-weight-normal">(*)</span>
            <div class="text-h6">
              {{ language.picture }}
            </div>
          </v-col>
          <v-col cols="10">
            <HnrInput
              v-model="content"
              :text="content"
              class="d-none"
            ></HnrInput>
            <CkContent :content="content" @getData="getContent"></CkContent>
            <span class="mt-4 validate-error text-danger text-danger">
              {{ contentErrorMessage }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.categories }}
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                :items="categoriesList"
                class="shrink money-box"
                dense
                item-text="title"
                v-model="categories"
                outlined
                return-object
                color="primary"
              ></v-select>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seoTitle
            }}<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="seoTitle" :text="seoTitle"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seoDescription
            }}<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-textarea
                outlined
                name="input-7-4"
                v-model="seoDescription"
              ></v-textarea>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.seoKey
            }}<span class="red--text font-weight-normal">(*)</span>
          </v-col>
          <v-col cols="10">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput v-model="seoKey" :text="seoKey"></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="h4 font-weight-black">
            {{ language.image
            }}<span class="red--text font-weight-normal">(*)</span>
            <div class="text-h6">
              {{ language.imageDisplay }}
            </div>
          </v-col>
          <v-col cols="10">
            <v-file-input
              class="pl-1"
              prepend-icon="mdi-camera"
              v-model="image"
              label="Upload image"
              @change="onChangeFileUpload"
              @click:clear="clearImage"
            ></v-file-input>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <HnrInput
                v-model="cardImg"
                :text="cardImg"
                class="d-none"
              ></HnrInput>
              <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
            <v-img
              v-if="cardImg"
              max-height="350"
              max-width="500"
              :src="cardImg"
            ></v-img>
            <span class="red--text text-h6">{{img_error_msg}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12 text-center">
            <span class="validate-error text-danger text-danger float-left">
              (*) : Mục cần nhập
            </span>
            <HnrButton
              :text="'Lưu'"
              :color="'primary'"
              :rounded="true"
              :outlined="true"
              :width="400"
              type="submit"
              form="check-login-form"
            ></HnrButton>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import CkContent from "@/components/ck-content";
import {
  SAVE_NEW,
  UPLOAD_FLASHCARD_IMAGE,
  GET_LIST_CATEGORIES
} from "@/store/new.module";
import HnrInput from "@/components/elements/hnr-input";
import HnrButton from "@/components/elements/hnr-button";
import { mapGetters } from "vuex";

export default {
  name: "form",
  //form to create
  data: () => {
    return {
      alerts: true,
      language: {
        title: "Tên bài viết",
        slug: "slug",
        content: "Nội dung bài viết",
        image: "Ảnh tiêu đề",
        seoTitle: "SEO Title",
        seoDescription: "SEO Description",
        seoKey: "SEO Keyword",
        user_full_name: "Tên tác giả",
        imageDisplay: "(547px x 1102px)",
        picture: "Ảnh (764px x 638px)",
        categories: "Mục lục"
      },
      user_full_name: "",
      file: null,
      cardImg: "",
      title: "",
      content: "",
      slug: "",
      user_id: "",
      seoDescription: "",
      seoTitle: "",
      seoKey: "",
      check: "",
      image: {},
      categories: 0,
      contentErrorMessage: "",
      img_error_msg:""
    };
  },

  components: {
    CkContent,
    HnrInput,
    HnrButton
  },
  mounted() {
    this.$store.dispatch(GET_LIST_CATEGORIES, 0);
  },
  computed: {
    ...mapGetters({
      categoriesList: "getListCategories"
    })
  },
  watch: {
    title: function(val) {
      this.slug = this.sanitizeTitle(val);
    },

    detail: function(val) {
      this.title = val.title;
      this.content = val.content;
      this.slug = val.slug;
      this.seoKey = val.seo_key;
      this.seoDescription = val.seo_description;
      this.seoTitle = val.seo_title;
    },
    content: function(val) {
      if (val.length > 0) {
        this.contentErrorMessage = "";
      } else {
        this.contentErrorMessage = "Vui lòng nhập nội dung của bài viết";
      }
    }
  },

  methods: {
    onChangeFileUpload() {
      this.cardImg = URL.createObjectURL(this.image);
      this.alerts = false;
    },
    clearImage() {
      this.cardImg = "";
    },
    sanitizeTitle(title) {
      // Change to lower case
      var str = title.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
      // Trim the last whitespace
      str = str.replace(/\s*$/g, "");
      // Change whitespace to "-"
      str = str.replace(/\s+/g, "-");

      return str;
    },

    getContent(value) {
      this.content = value;
    },

    getSeoDescription(value) {
      this.seoDescription = value;
    },
    //save news
    async saveData() {
      if (this.content.length == 0) {
        this.contentErrorMessage = "Vui lòng nhập nội dung của bài viết";
      } else {
        this.contentErrorMessage = "";
        let formData = new FormData();
        formData.append("file", this.image);
        if (
          this.image.type === "image/jpeg" ||
          this.image.type === "image/png" ||
          this.image.type === "image/webp"
        ) {
          await this.$store
            .dispatch(UPLOAD_FLASHCARD_IMAGE, formData)
            .then(data => {
              this.file = data.data.data;
            })
            .catch(err => {
              console.log("err", err);
            });
          var data = {
            title: this.title,
            content: this.content,
            slug: this.slug,
            user_full_name: this.user_full_name,
            seo_key: this.seoKey,
            seo_description: this.seoDescription,
            seo_title: this.seoTitle,
            check: 0,
            date: this.getCurrentDate(),
            image_url: this.file,
            categories_id: this.categories.id
          };
          await this.$store.dispatch(SAVE_NEW, data);
          this.$router.push({
            path: "/new/list"
          });
        } else {
          this.img_error_msg = "file nhập vào phải là một hình ảnh"
        }
      }
    },
    getCurrentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;

      return today;
    }
  }
};
</script>

<style></style>
